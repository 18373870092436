import type { FC } from '../../../../lib/teact/teact';
import React, { memo } from '../../../../lib/teact/teact';

const SvgFinish: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5 64.5C50.1731 64.5 64.5 50.1731 64.5 32.5C64.5 14.8269 50.1731 0.5 32.5 0.5C14.8269 0.5 0.5 14.8269 0.5 32.5C0.5 50.1731 14.8269 64.5 32.5 64.5ZM27.9665 44.5014C27.4079 44.5014 26.8492 44.2436 26.4625 43.8568L17.9971 35.4344C17.1377 34.575 17.1377 33.2429 17.9971 32.3834C18.8565 31.524 20.1887 31.524 21.0481 32.3834L27.9665 39.2589L43.9519 23.2735C44.8113 22.414 46.1435 22.414 47.0029 23.2735C47.8623 24.1329 47.8623 25.465 47.0029 26.3245L29.4705 43.8568C29.0838 44.2866 28.5251 44.5014 27.9665 44.5014Z" fill="var(--color-primary)" />
    </svg>
  );
};

export default memo(SvgFinish);
