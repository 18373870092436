import type { FC } from '../../../../lib/teact/teact';
import React, { memo } from '../../../../lib/teact/teact';

type OwnProps = {
  color: string;
};

const SvgInboxTelegram: FC<OwnProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="40" viewBox="0 0 49 40" fill="none">
      <path d="M44.4289 0.345756C38.0966 2.93435 10.9447 14.0349 3.44266 17.0604C-1.58856 18.9983 1.35678 20.8153 1.35678 20.8153C1.35678 20.8153 5.65154 22.2686 9.33335 23.3587C13.0146 24.4488 14.978 23.2378 14.978 23.2378L32.2806 11.7313C38.4162 7.61323 36.9438 11.0044 35.4709 12.4582C32.2806 15.6076 27.0039 20.573 22.5861 24.5702C20.6227 26.2658 21.6042 27.7191 22.4636 28.446C25.6539 31.1107 34.367 36.5612 34.8574 36.9244C37.4497 38.7358 42.5489 41.3432 43.3245 35.8343L46.3923 16.8186C47.3742 10.3994 48.3557 4.46435 48.4782 2.76876C48.8467 -1.34984 44.4289 0.345756 44.4289 0.345756Z" fill={color} />
    </svg>
  );
};

export default memo(SvgInboxTelegram);
